






















































import {Component, Vue, Watch} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import PaymentForm from "@/components/for-payments-view/PaymentForm.vue";
import PaymentDisabled from "@/components/for-payments-view/PaymentDisabled.vue";
import HelpCard from "@/components/for-payments-view/HelpCard.vue";
import {mapGetters, mapState} from "vuex";
import {paymentForm} from "@/assets/scripts/form-fields/main";

const { values } = paymentForm;

@Component({
  components: { Page, PaymentForm, HelpCard, PaymentDisabled },
  computed: {
    ...mapState({ organization: "organization" }),
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({
      contactList: "organization/contactList",
      contractInactive: "contract/inactive",
      contractClosed: "contract/closed",
    }),
  },
})
class Payments extends Vue {
  [x: string]: any
  values = values
  documents = [];

  mounted() {
    this.values = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
    this.defaultValues = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
    this.documents = JSON.parse(this.$route.params.docs)
    console.log(this.documents)
    console.log(this.organization)
  }

  get paymentDisabled() {
    return this.contractInactive || this.contractClosed;
  }

  get amount() {
    return this.$route.params.amount || 0
  }

  get total() {
    const { appEnvironment } = this;
    return +this.amount + (+this.amount * (+appEnvironment.constants["PaymentFee"] || 0))
  }

  get userEmail() {
    let email = '';
    this.contactList.forEach((item) => {
      if (item['тип'] == 'Email')
        email = item['значение']
    });
    return email;
  }

  get docs() {
    return this.$route.params.docs || '[]'
  }
}

export default Payments;
